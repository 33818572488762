import React from "react";
import Booksv1 from "../booksv1";
import Authorv3 from "../authorv3";
import Testimonial from "../testimonial";
import Contact from "../contact";
import Footer from "../global/footer";
import Header from "../global/header";
import Herov2 from "../herov2";
import Pricing from "../pricing";

const Version02 = ({ header, footer }) => {
  //const { menu } = footer;
  return (
    <>
      <Header header={header} />
      <Herov2 isBg="yes" />
      {/* <Achievev2 isBg="" /> */}
      <Booksv1 isBg="" />
      <Authorv3 isBg="yes" />
      {/* <ChapterPreview isBg="" /> */}
      <Pricing isBg="" />
      <Testimonial isBg="yes" />
      <Contact isBg="" />
      <Footer isBg="yes" />
    </>
  );
};

export default Version02;
