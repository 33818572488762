/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
//import CtaV3 from "../ctav3";
import data from "../../data/footer.json";

const Footer = ({ menu, isBg }) => {
  const { footer } = data;

  return (
    <>
      {/* <CtaV3 /> */}
      <footer
        className={`footer overflow-hidden ${isBg === "yes" ? "bg-one" : ""}`}
      >


        <div className="footer__copyright m-20px-t m-20px-b">
          <div className="row">
            <div className="col-12">
              <p className="m-0 text-center">
                &copy; {footer.copyright}
                <img
                  className="img-fluid"
                  src={footer.icon}
                  alt="footer icon"
                  width="20"
                  height="20"
                />
                &nbsp; {footer.name} AT - Publications
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
    // <!-- ========== Footer section End ========== -->
  );
};

export default Footer;
